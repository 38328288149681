import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';


export const BannerWrapStyled = styled.div`
    width: 100%;
    padding-top: ${palette.headerTabletHeight};

    @media ${device.tablet} {
        padding-top: ${palette.headerHeight};
    }

    .banner-outer {
        width: 100%;
        /* background-color: ${palette.lightBg}; */
    }

    .banner-wrapper {
        position: relative;
        max-width: ${width.xxl};
        margin: 0 auto;

    }

    .textContainer {
        position: absolute;
        top: 20%;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;

        @media ${device.mobile} {
            top: 30%;
        }

        @media ${device.laptop} {
            width: 70%;
            text-align: left;
            padding-left: 5rem;
            padding-right: 2rem;
        }

        @media ${device.desktop} {
            width: 50%;
        }

        .bigText {
            font-size: 1.2rem;
            color: ${palette.darkGrey};
            /* text-transform: uppercase; */
            line-height: 1.5rem;
            margin-bottom: 1rem;
            font-weight: 600;

            @media ${device.mobile} {
                font-size: 1.8rem;
                width: 70%;
                margin-left: auto;
                margin-right: auto;
                line-height: 2.5rem;
            }

            @media ${device.laptop} {
                font-size: 2rem;
                width: 100%;
            }
        }

        .text-3 {
            line-height: 1.5rem;
        }
    }

    .wrapper-section {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        max-width: ${width.xxl}; // Image width
        height: 400px;

        @media ${device.mobile} {
            height: 600px;
        }

        &.slider {
            .image-slide {
                transition: all 0.2s ease-out;
                object-fit: cover;
                max-width: 100%;
            }
        }

        &.no-slider {
                .gatsby-image-wrapper {
                width: 100%;
            }
        }

    }

    &.heroProfile {
        max-width: 100%;

        .wrapper-section, .gatsby-image-wrapper {
            height: 300px;
            max-width: 100%;

            @media ${device.tablet} {
                height: 400px;

            }
        }
    }

    &.heroProducts {
        padding-top: ${palette.headerTabletHeight};

        @media ${device.tablet} {
            padding-top: ${palette.headerWithTopHeight};
        }

        .wrapper-section, .gatsby-image-wrapper {
            height: 300px;

            img {
                object-position: right !important;

                @media ${device.mobile} {
                    object-position: center top !important;
                }
            }

            @media ${device.tablet} {
                height: 400px;
            }
        }
    }

    &.heroFraud {
        padding-top: ${palette.headerTabletHeight};

        @media ${device.tablet} {
            padding-top: ${palette.headerWithTopHeight};
        }

        .banner-wrapper {
            max-height: 400px;
        }

        .wrapper-section, .gatsby-image-wrapper {
            height: auto;

            img {
                object-position: center !important;
                max-height: 400px;
                height: auto !important;
            }

            @media ${device.tablet} {
                height: auto;
            }
        }

        .gatsby-image-wrapper {

            div:first-child {

                @media ${device.laptop} {
                    padding-bottom: 30% !important;
                }
            }
        }
    }

    &.contactHero {

        .wrapper-section, .gatsby-image-wrapper {
            height: 300px;

            @media ${device.tablet} {
                height: 400px;
            }
        }

        .container {
            margin-top: 0;
        }

        .contactCta {
            display: none;
        }

        .banner-outer {
            background-color: ${palette.lightBg};
        }
    }
`;
