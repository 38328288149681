import React from "react"
import {ContactAddressStyled} from './contactAddressStyled'
import ThFlag from './icons/thFlag'
import Email from './icons/emailSolid'
import UsFlag from './icons/usFlag'
import CanadaFlag from './icons/canadaFlag'






const ContactAddress = () => {
    return <ContactAddressStyled>
                <div className="addressWrapper">
                    <div className="addressSection">
                        <div className="contactWrap">
                            {/* <Brand name="contact-brand" /> */}
                            <div className="detailWrap">
                                <div className="emailWrap">
                                    <Email name="emailHeadBar" />
                                    <p className="email">info@savie-glove.com</p>
                                </div>
                                <div className="addressWrap">
                                    <div className="address-2">
                                        <ThFlag name="th"/>
                                        <p className="address">
                                            <span className="title">Head Office:</span><br />1697/9, Ladprao Road,<br />Samsennok Subdistrict, Huaykwang,<br />Bangkok 10310, Thailand
                                            <br /><span className="ref">Reistration No : 0105559042161</span>
                                        </p>
                                    </div>

                                    <div className="address-3">
                                        <UsFlag name="us"/>
                                        <p className="address">
                                            <span className="title">USA Post Address:</span><br />Box 1222 Congress AZ 85332
                                        </p>
                                        <p className="address">
                                            <span className="title">Contact Person:</span><br />Stephen Masters<br /><span className="position">COO/Partner<br />SAVIE GROUP CORP (SGC) USA</span>
                                        </p>
                                    </div>

                                    <div className="address-4">
                                        <CanadaFlag name="cn"/>
                                        <p className="address">
                                            <span className="title">Canada Address:</span><br />
                                            SAVIE - ALLEN CORPORATION (CAN)<br />
                                            Suite 2800,<br />
                                            Park Place 666 Burrad Street,<br />
                                            Vancouver, BC V6C 2Z7, CANADA
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <iframe src="https://maps.google.com/maps?q=Bang%20Lamung%20District,%20%E0%B8%8A%E0%B8%A5%E0%B8%9A%E0%B8%B8%E0%B8%A3%E0%B8%B5%20Thailand%2020000&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            frameBorder="0" allowFullScreen="" title="Savie map" className="map"></iframe>
                </div>
            </ContactAddressStyled>
}
 export default ContactAddress
{/* <div class="mapouter"><div class="gmap_canvas"><iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Bang%20Lamung%20District,%20%E0%B8%8A%E0%B8%A5%E0%B8%9A%E0%B8%B8%E0%B8%A3%E0%B8%B5%20Thailand%2020000&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://123movies-to.org">123movies</a><br><style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}</style><a href="https://google-map-generator.com">google-map-generator.com</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style></div></div> */}