import React, {useEffect, useState} from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import Particle from './particle'
import {BannerWrapStyled} from './heroStyled'
import PropTypes from 'prop-types'
import ButtonCta from './buttonCta'
import {slideNext} from "./helper/slideNext";




const Hero = ({position, children, buttonText, classButton, iconButton, className, isSlider}) => {

  const [mouseOn, setMouseOn] = useState(false)
  useEffect(() => {
    const slideWrapper = document.getElementById('slideWrapper')
    if(!mouseOn) {
        const handleInterval = setInterval(() => {
            slideNext(slideWrapper)
        }, 2000);
        return () => clearInterval(handleInterval);
    }
    });

    const mouseEnter = () => {
      setMouseOn(true)
    }
    const mouseLeave = () => {
      setMouseOn(false)
    }

  const wrapperClass = isSlider && isSlider ? 'slider' : ''

  const data = useStaticQuery(graphql`
  query HeroQuery {
    allContentfulHero {
      edges {
        node {
          image {
            file {
              url
            }
            sizes(quality: 90, maxHeight: 1000, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
              sizes
              src
              srcSet
              base64
            }
          }
          name
          id
          text1
          text2
          text3
          bannerPosition
          displayParticles
          bannerImage {
            file {
              url
            }
            title
            sizes(maxHeight: 1000, quality: 90, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
              sizes
              src
              srcSet
              base64
            }
          }
          bannerImage2 {
            file {
              url
            }
            title
            sizes(maxHeight: 1000, quality: 90, resizingBehavior: PAD) {
              ...GatsbyContentfulSizes_withWebp
              sizes
              src
              srcSet
              base64
            }
          }
        }
      }
    }
  }
  `)
  if(!data.allContentfulHero.edges) {
    return <div>Loading...</div>
  } else {
    return data.allContentfulHero.edges.map((edge) => {
      if(edge.node && edge.node.bannerPosition &&
        edge.node.name && (position === edge.node.bannerPosition[0])) {
        return <BannerWrapStyled key={edge.node.id} className={className}>
                    {!isSlider || isSlider === undefined ?
                     <div className="banner-outer">
                       <div className="banner-wrapper">
                          <div className={`wrapper-section no-slider`}>
                            {edge.node.image && <Img alt={edge.node.name} sizes={edge.node.image.sizes} placeholderStyle={{ visibility: "hidden" }}/>}
                         </div>
                        </div>
                      </div> :
                      <div className="banner-outer">
                        <div className="banner-wrapper">
                          <div className={`wrapper-section ${wrapperClass}`} id="slideWrapper" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                            {edge.node.image && <img src={edge.node.image && edge.node.image.file.url} className="image-slide" alt={`savie-glove-slide`} />}
                            {edge.node.bannerImage && <img src={edge.node.bannerImage && edge.node.bannerImage.file.url} className="image-slide" alt={`savie-glove-slide`} />}
                            {edge.node.bannerImage2 && <img src={edge.node.bannerImage2 && edge.node.bannerImage2.file.url} className="image-slide" alt={`savie-glove-slide`} />}
                          </div>
                          {edge.node.displayParticles && <Particle />}
                          <div className="textContainer">
                            {edge.node.text1 && <p className="text-1">{edge.node.text1}</p>}
                            {edge.node.text2 && <h1 className="bigText">{edge.node.text2}</h1>}
                            {edge.node.text3 && <p className="text-3">{edge.node.text3}</p>}
                            {buttonText && <ButtonCta text={buttonText} className={classButton} iconButton={iconButton} linkTo="/contact/" isCta={true} />}
                          </div>
                        </div>
                      </div>}


                    {children}
                </BannerWrapStyled>
      }
      return true;
    })
  }
}

Hero.propTypes = {
  position: PropTypes.oneOf(['Home', 'Fraud', 'Profile', 'Products', 'Contact']).isRequired, // Nav
  className: PropTypes.string.isRequired,
  isSlider: PropTypes.bool
}

export default Hero
