import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from "gatsby"
import Hero from '../components/hero'
import ContactAddress from '../components/contactAddress'


const Contact = () => {
    const data = useStaticQuery(graphql`
    query ContactPageQuery {
        allContentfulMetaDescription(filter: {position: {eq: "Contact"}}) {
        edges {
            node {
            text
            }
        }
        }
    }
    `)
  if(!data.allContentfulMetaDescription.edges) {
    return <div>Loading...</div>
  }
  const descriptionMeta = data.allContentfulMetaDescription.edges[0].node.text ? data.allContentfulMetaDescription.edges[0].node.text : "Top Nitrile and Latex Gloves Manufacturer in Asia | Savie Glove by Savie Group"
    return (
        <Layout hideContact={false} showMarketingPopup={true}>
        <SEO title="Savie Glove Contact" description={descriptionMeta} />
        <Hero position="Contact"
                buttonText="Contact us"
                classButton="contactCta"
                className="contactHero">
        </Hero>
        <ContactAddress />
    </Layout>
    )

}

export default Contact
