export const slideNext = (sliderWrapper) => {
    if(sliderWrapper) {
        changeSlidePosition(0, '', 'beforeEnd', sliderWrapper)
        const sliderItemWidth = `${sliderWrapper && sliderWrapper.children[0] && sliderWrapper.children[0].offsetWidth}`
        const item = sliderWrapper.children[0]
        item.style.marginLeft = `-${sliderItemWidth}px`
    }
}

const changeSlidePosition = (i, marginLeft, position, sliderWrapper) => {
    const slideToChange = sliderWrapper.children[i]
    slideToChange.style.marginLeft = marginLeft;
    sliderWrapper.removeChild(slideToChange);
    sliderWrapper.insertAdjacentHTML(position, slideToChange.outerHTML);
}