import React from 'react'
import Particles from 'react-particles-js';



const particleOpt = {
    particles: {
        number: {
            value: 1000,
            density: {
                enable: true,
                value_area: 8000
            }
        }
    }
}

const Particle = () => {
  return <Particles
                className='particles'
                canvasClassName="canvasParticles"
                params={particleOpt}
                style={{
                    width: '100%',
                    // backgroundImage: palette.gradient,
                    position: 'absolute',
                    top: 0,
                    opacity: 0.5
            }}
        />
}


export default Particle
