import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';


export const ContactAddressStyled = styled.div`
    background-color: ${palette.white};

    @media ${device.tablet} {
        flex-direction: row;
    }

    .addressWrapper {
        max-width: ${width.xxl};
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media ${device.tablet} {
            flex-direction: row;
        }
    }

    .map {
        width: 100%;
        height: auto;
        margin-bottom: 0;
        min-height: 300px;

        @media ${device.tablet} {
            width: 50%;
        }
    }

    .addressSection {
        padding: 5rem 15px 5rem 15px;
        flex-basis: 50%;
        color: white;

        .titleContactWrap {
            text-align: center;
            width: 100%;
            margin: 0 auto;

            @media ${device.tablet} {
                width: 80%;
            }

            .underLine {
                width: 50%;
                border-bottom: 5px solid ${palette.white};
                margin: 0 auto;
            }
        }

        .brandLogo {
            width: 90px;
            display: block;
            margin: 0 auto;
        }

        .contactWrap {
            width: 80%;
            margin: 0 auto;
            padding-top: 0;

            .logoWrap {
                text-align: center;

                g.savie {
                    path {
                        fill: ${palette.cta};
                    }
                }

                g.symbol {
                    path, polygon {
                        fill: ${palette.gold};
                    }
                }

            }


            .detailWrap {

                svg {
                    box-sizing: content-box;

                    &.th {
                        border: none;
                        width: 40px;
                        height: 40px;
                    }

                    &.us {
                        border: none;
                        width: 40px;
                        height: 40px;
                    }

                    &.cn {
                        border: none;
                        width: 40px;
                        height: 40px;
                    }
                }

                .emailWrap, .addressWrap {
                    font-size: 30px;
                    width: 100%;
                    text-align: center;

                    svg {
                        color: ${palette.gold};
                    }

                    .address-3 svg > path {
                        fill: ${palette.gold};
                    }

                    .address-4 svg > path.pin {
                        fill: ${palette.gold};
                    }
                }

                .emailWrap {
                    margin-bottom: 3rem;
                }

                .email, .address {
                    font-size: ${palette.fontSizeSm};
                    margin-top: 10px;
                    color: ${palette.darkGrey};
                    line-height: 1.4rem;

                    .title {
                        font-weight: 600;
                    }

                    .position, .ref {
                        font-style: italic;
                        font-size: 0.7rem;
                        line-height: 18px;
                        display: inline-block;
                        margin-top: .6rem;
                    }
                }
            }
        }
    }
`;
