import React from "react"
import * as palette from '../../styles/variables'



const ThFlag = ({name}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="240px" height="300px" viewBox="80.5 -335.75 240 300" className={name}>
    <g>
	<path fill={`${palette.gold}`} d="M301.786-268.254c-18.874-36.773-56.8-62.814-101.298-62.814v57.52c31.478,0,57.093,24.79,57.093,56.285
		c0,31.105-25.828,57.927-57.093,57.927v118.905c40.099-41.793,86.256-87.737,106.442-142.72
		C317.042-210.655,315.162-242.231,301.786-268.254z"/>
	<path fill={`${palette.gold}`} d="M94.063-183.151c20.187,54.982,66.344,100.927,106.443,142.72v-118.905
		c-31.273,0-57.102-26.821-57.102-57.927c0-31.495,25.615-56.285,57.102-56.285v-57.52c-44.489,0-82.425,26.041-101.299,62.814
		C85.841-242.231,83.96-210.655,94.063-183.151z"/>
	<path fill="#F0F0F0" d="M200.496-309.054c50.166,0,90.824,40.657,90.824,90.823c0,50.148-40.658,90.824-90.824,90.824
		c-50.156,0-90.823-40.676-90.823-90.824C109.673-268.396,150.34-309.054,200.496-309.054z"/>
	<path fill="#0052B4" d="M285.68-249.823H115.323c-3.654,9.836-5.65,20.479-5.65,31.585c0,11.113,1.996,21.756,5.65,31.593H285.68
		c3.654-9.837,5.641-20.479,5.641-31.593C291.32-229.344,289.334-239.987,285.68-249.823z"/>
	<g>
		<path fill="#D80027" d="M200.496-309.063c-27.53,0-52.196,12.248-68.845,31.593h137.69
			C252.685-296.814,228.027-309.063,200.496-309.063z"/>
		<path fill="#D80027" d="M269.342-158.999h-137.69c16.648,19.335,41.314,31.575,68.845,31.575
			C228.027-127.424,252.685-139.664,269.342-158.999z"/>
	</g>
	<path fill="#FFFFFF" d="M200.453-318.748c-41.856,0-80.296,26.999-94.576,66.309c-14.387,39.602-1.782,85.05,30.645,111.854
		c32.488,26.892,79.675,30.439,115.773,8.621c36.116-21.854,55.203-65.111,46.725-106.487c-8.338-40.525-42.042-72.678-82.93-79.081
		C210.927-318.341,205.686-318.748,200.453-318.748 M281.44-181.67c-17.172,38.032-60.012,59.497-100.749,50.166
		c-31.345-7.148-56.986-31.646-65.724-62.539c-8.94-31.594,0.755-66.309,24.648-88.793c25.847-24.338,64.811-30.98,97.245-16.355
		c38.742,17.474,60.171,61.396,49.759,102.665C285.324-191.436,283.586-186.469,281.44-181.67
		C280.251-179.045,282.309-183.63,281.44-181.67"/>
</g>
</svg>

    )
}

export default ThFlag
