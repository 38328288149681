import React from "react"



const CanadaFlag = ({name}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" width="240px" height="300px" className={name}>
<path fill="#AB8D3F" className="pin" d="M434.4,110.7C401.2,45.9,334.4,0,256,0v101.3c55.5,0,100.6,43.7,100.6,99.2c0,54.8-45.5,102-100.6,102.1V512
	c70.6-73.6,152-154.5,187.5-251.4C461.3,212.1,458,156.5,434.4,110.7z"/>
<path fill="#AB8D3F" className="pin" d="M68.5,260.6C104.1,357.5,185.4,438.4,256,512V302.5c-55.1,0-100.6-47.2-100.6-102.1
	c0-55.5,45.1-99.1,100.6-99.2V0C177.6,0,110.8,45.9,77.6,110.7C54,156.5,50.7,212.1,68.5,260.6z"/>
<path fill="#FFFFFF" d="M255.9,21.7c-73.7,0-141.5,47.6-166.6,116.8c-25.3,69.8-3.1,149.8,54,197.1c57.2,47.3,140.4,53.6,204,15.1
	s97.2-114.7,82.3-187.6c-14.7-71.4-74-128-146.1-139.3C274.4,22.4,265.1,21.7,255.9,21.7 M398.6,263.2
	c-30.2,67-105.7,104.8-177.5,88.4c-55.2-12.6-100.4-55.8-115.8-110.2c-15.7-55.7,1.3-116.8,43.4-156.4
	C194.2,42.1,262.9,30.4,320,56.2C388.2,87,426,164.3,407.7,237C405.5,246,402.4,254.7,398.6,263.2
	C396.5,267.8,400.1,259.7,398.6,263.2"/>
<g>
	<circle fill="#F0F0F0" cx="256" cy="198.31" r="160.1"/>
	<g>
		<path fill="#D80027" d="M416.1,198.31c0-63.47-36.94-118.32-90.49-144.21v288.43C379.16,316.63,416.1,261.79,416.1,198.31z"/>
		<path fill="#D80027" d="M95.9,198.31c0,63.47,36.94,118.32,90.49,144.21V54.1C132.84,80,95.9,134.84,95.9,198.31z"/>
		<polygon fill="#D80027" points="283.84,219.2 311.69,205.27 297.77,198.31 297.77,184.39 269.92,198.31 283.84,170.47
			269.92,170.47 256,149.59 242.08,170.47 228.16,170.47 242.08,198.31 214.23,184.39 214.23,198.31 200.31,205.27 228.16,219.2
			221.2,233.12 249.04,233.12 249.04,254 262.96,254 262.96,233.12 290.8,233.12 		"/>
	</g>
</g>
</svg>

    )
}

export default CanadaFlag
