import React from "react"



const UsFlag = ({name}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" enable-background="new 0 0 512 512" className={name}>
<path fill="#B3B3B3" d="M434.44,110.65C401.18,45.88,334.36,0,255.98,0v101.33c55.48,0,100.59,43.67,100.59,99.15
	c0,54.8-45.49,102.05-100.59,102.05V512c70.64-73.62,151.95-154.54,187.53-251.43C461.3,212.12,457.98,156.5,434.44,110.65z"/>
<path fill="#979797" d="M68.49,260.57C104.07,357.46,185.37,438.38,256.02,512V302.53c-55.1,0-100.6-47.25-100.59-102.05
	c0-55.48,45.12-99.15,100.59-99.15V0c-78.38,0-145.2,45.88-178.46,110.65C54.02,156.5,50.7,212.12,68.49,260.57z"/>
<path fill="#C7C7C7" d="M255.93,21.71c-73.75,0-141.45,47.55-166.61,116.81c-25.34,69.77-3.14,149.84,53.97,197.07
	c57.24,47.34,140.37,53.63,203.98,15.15c63.63-38.49,97.23-114.68,82.27-187.57C414.89,91.77,355.52,35.12,283.47,23.84
	C274.36,22.42,265.14,21.71,255.93,21.71 M398.59,263.18c-30.24,67.01-105.73,104.8-177.48,88.4
	c-55.21-12.62-100.38-55.76-115.78-110.2c-15.75-55.66,1.33-116.8,43.42-156.43c45.53-42.87,114.17-54.57,171.32-28.8
	c68.23,30.77,105.97,108.14,87.65,180.85C405.46,245.99,402.37,254.74,398.59,263.18C396.5,267.81,400.13,259.74,398.59,263.18"/>
<g>
	<polygon fill="#D80027" points="417.72,198.69 417.72,198.69 417.72,198.69 	"/>
	<path fill="#F0F0F0" d="M116.15,123.76c0.54-1.02,1.09-2.03,1.65-3.04"/>
	<path fill="#F0F0F0" d="M98.03,211.13c0.74,9.59,2.32,18.94,4.67,27.98h309.86c3.36-12.91,5.15-26.45,5.15-40.42H256.06v12.43
		H98.03z"/>
	<path fill="#F0F0F0" d="M153.12,319.94h209.04c13.32-11.49,24.72-25.15,33.67-40.42H119.45
		C128.4,294.79,139.8,308.45,153.12,319.94z"/>
	<path fill="#F0F0F0" d="M362.17,77.45c-28.05-24.2-64.58-38.84-104.53-38.84c-0.53,0-1.05,0.01-1.58,0.02v38.82H362.17z"/>
	<path fill="#F0F0F0" d="M256.06,158.28h156.51c-3.73-14.36-9.41-27.92-16.74-40.42H256.06V158.28z"/>
	<path fill="#D80027" d="M256.06,117.86h139.78c-8.95-15.27-20.35-28.92-33.67-40.42H256.06V117.86z"/>
	<path fill="#D80027" d="M256.06,158.28v40.42h161.66c0,0,0,0,0-0.01c0-13.96-1.79-27.5-5.15-40.41H256.06z"/>
	<path fill="#D80027" d="M119.45,279.52h276.38c7.32-12.49,13-26.06,16.74-40.42H102.71C106.44,253.46,112.12,267.03,119.45,279.52z
		"/>
	<path fill="#D80027" d="M257.64,358.77c39.95,0,76.47-14.63,104.52-38.83H153.12C181.16,344.14,217.69,358.77,257.64,358.77z"/>
	<path fill="#2E52B2" d="M124.33,117.86h-4.89c-0.56,0.95-1.1,1.9-1.64,2.86h5.59L124.33,117.86z"/>
	<path fill="#2E52B2" d="M173.01,77.45l2.22-6.82l2.21,6.82h43.87l2.22-6.82l2.21,6.82h30.32V38.62
		C216.72,39,180.78,53.56,153.1,77.45H173.01z"/>
	<path fill="#2E52B2" d="M98.03,211.13h158.03v-12.43H97.55C97.55,202.88,97.72,207.02,98.03,211.13z"/>
	<path fill="#2E52B2" d="M226.13,117.86l0.93,2.86h11.43l-9.25,6.72l3.53,10.87l-9.24-6.72l-9.25,6.72l3.53-10.87l-9.25-6.72H220
		l0.93-2.86h-43.1l0.93,2.86h11.43l-9.25,6.72l3.53,10.87l-9.24-6.72l-9.25,6.72l3.53-10.87l-9.25-6.72h11.43l0.93-2.86h-43.1
		l0.93,2.86h11.43l-9.25,6.72l3.53,10.87l-9.24-6.72l-9.25,6.72l3.53-10.87l-5.07-3.68c-5.75,10.83-10.29,22.39-13.45,34.52h21.24
		l2.99-9.2l2.99,9.2h42.32l2.99-9.2l2.99,9.2h42.32l2.99-9.2l2.99,9.2h29.54v-40.42H226.13z"/>
	<path fill="#2E52B2" d="M126.93,109.85l2.6,8.01h43.1l2.6-8.01l2.6,8.01h43.1l2.6-8.01l2.6,8.01h29.93V77.45h-30.32l1.31,4.05
		h11.43l-9.25,6.71l3.53,10.87l-9.24-6.72l-9.25,6.72l3.53-10.87l-9.25-6.71H220l1.32-4.05h-43.87l1.31,4.05h11.43l-9.25,6.71
		l3.53,10.87l-9.24-6.72l-9.25,6.72l3.53-10.87l-9.25-6.71h11.43l1.32-4.05H153.1c-6.49,5.6-12.51,11.71-18.03,18.27l1.09,3.37
		l-2.43-1.77c-5.28,6.45-10.07,13.32-14.3,20.55h4.89L126.93,109.85z"/>
	<path fill="#2E52B2" d="M227.06,159.95h11.43l-9.25,6.72l3.53,10.87l-9.24-6.71l-9.25,6.71l3.53-10.87l-9.25-6.72H220l0.54-1.67
		h-42.32l0.54,1.67h11.43l-9.25,6.72l3.53,10.87l-9.24-6.71l-9.25,6.71l3.53-10.87l-9.25-6.72h11.43l0.54-1.67h-42.32l0.54,1.67
		h11.43l-9.25,6.72l3.53,10.87l-9.24-6.71l-9.25,6.71l3.53-10.87l-9.25-6.72h11.43l0.54-1.67H102.7
		c-3.36,12.91-5.15,26.45-5.15,40.41c0,0,0,0,0,0.01h158.5v-40.42h-29.54L227.06,159.95z"/>
	<path fill="#F0F0F0" d="M117.68,138.31l9.25-6.72l9.24,6.72l-3.53-10.87l9.25-6.72h-11.43l-0.93-2.86h-5.2l-0.93,2.86h-5.59
		c-0.56,1.01-1.11,2.02-1.65,3.04l5.07,3.68L117.68,138.31z"/>
	<polygon fill="#F0F0F0" points="124.33,117.86 129.53,117.86 126.93,109.85 	"/>
	<polygon fill="#F0F0F0" points="126.93,149.08 123.94,158.28 129.92,158.28 	"/>
	<polygon fill="#F0F0F0" points="111.97,159.95 121.21,166.66 117.68,177.53 126.93,170.82 136.17,177.53 132.64,166.66
		141.89,159.95 130.46,159.95 129.92,158.28 123.94,158.28 123.4,159.95 	"/>
	<path fill="#F0F0F0" d="M135.08,95.71c-0.45,0.53-0.9,1.06-1.34,1.6l2.43,1.77L135.08,95.71z"/>
	<polygon fill="#F0F0F0" points="171.7,120.72 160.27,120.72 169.51,127.44 165.98,138.31 175.23,131.59 184.47,138.31
		180.94,127.44 190.19,120.72 178.76,120.72 177.83,117.86 172.63,117.86 	"/>
	<polygon fill="#F0F0F0" points="172.63,117.86 177.83,117.86 175.23,109.85 	"/>
	<polygon fill="#F0F0F0" points="175.23,149.08 172.24,158.28 178.22,158.28 	"/>
	<polygon fill="#F0F0F0" points="160.27,159.95 169.51,166.66 165.98,177.53 175.23,170.82 184.47,177.53 180.94,166.66
		190.19,159.95 178.76,159.95 178.22,158.28 172.24,158.28 171.7,159.95 	"/>
	<polygon fill="#F0F0F0" points="223.53,149.08 220.54,158.28 226.52,158.28 	"/>
	<polygon fill="#F0F0F0" points="208.57,159.95 217.81,166.66 214.28,177.53 223.53,170.82 232.77,177.53 229.24,166.66
		238.49,159.95 227.06,159.95 226.52,158.28 220.54,158.28 220,159.95 	"/>
	<polygon fill="#F0F0F0" points="220,120.72 208.57,120.72 217.81,127.44 214.28,138.31 223.53,131.59 232.77,138.31 229.24,127.44
		238.49,120.72 227.06,120.72 226.13,117.86 220.93,117.86 	"/>
	<polygon fill="#F0F0F0" points="220.93,117.86 226.13,117.86 223.53,109.85 	"/>
	<polygon fill="#F0F0F0" points="175.23,70.63 173.01,77.45 177.44,77.45 	"/>
	<polygon fill="#F0F0F0" points="160.27,81.5 169.51,88.21 165.98,99.08 175.23,92.37 184.47,99.08 180.94,88.21 190.19,81.5
		178.76,81.5 177.44,77.45 173.01,77.45 171.7,81.5 	"/>
	<polygon fill="#F0F0F0" points="223.53,70.63 221.31,77.45 225.74,77.45 	"/>
	<polygon fill="#F0F0F0" points="208.57,81.5 217.81,88.21 214.28,99.08 223.53,92.37 232.77,99.08 229.24,88.21 238.49,81.5
		227.06,81.5 225.74,77.45 221.31,77.45 220,81.5 	"/>
</g>
</svg>

    )
}

export default UsFlag